<template>
  <div>
    <v-container v-if="isValid">
      <!-- {{$commonProcessor.userCountry($store.state.userProfile)}} -->
      <v-row class="d-flex justify-center">
        <v-col>
          <v-card class="elevation-16" width="auto">
            <!-- <v-app-bar dark color="#30D5C8">
              <v-icon large class="mr-3">data_usage</v-icon>
              <v-toolbar-title>iReview</v-toolbar-title>
            </v-app-bar> -->
            <v-stepper v-model="e1" vertical>
              <v-stepper-step color="#30D5C8" :complete="e1 > 1" step="1">
                Pick a Segment
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-card class="mt-1" flat>
                  <v-row no-gutters>
                    <v-col class="col-sm-12 col-md-4 col-lg-4">
                      <v-autocomplete label="Segments" :items="segments" :item-text="(item) => `${item.segment}`"
                        item-value="_id" v-model="dataToSend.segmentId" v-validate="'required'" required chips
                        deletable-chips small-chips dense outlined data-vv-name="segments"
                        :error-messages="errors.collect('segments')">
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>

                <v-row no-gutters>
                  <v-btn class="ma-2" color="success" fab small @click="loadCategories()"
                    :disabled="dataToSend.segmentId == undefined">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-row>
              </v-stepper-content>

              <v-stepper-step color="#30D5C8" :complete="e1 > 2" step="2">
                Pick a category
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-card class="mt-1" flat>
                  <v-row no-gutters>
                    <v-col class="col-sm-12 col-md-4 col-lg-4">
                      <v-autocomplete label="Categories" :items="categories" :item-text="(item) => `${item.category}`"
                        item-value="_id" v-model="dataToSend.categoryId" v-validate="'required'" required chips
                        deletable-chips small-chips dense outlined data-vv-name="categories"
                        :error-messages="errors.collect('categories')">
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>
                <v-row no-gutters>
                  <v-btn class="ma-2" color="primary" fab small @click="e1 = 1">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  <v-btn class="ma-2" color="success" fab small @click="loadSubcategories()"
                    :disabled="dataToSend.categoryId == undefined">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-row>
              </v-stepper-content>

              <v-stepper-step color="#30D5C8" :complete="e1 > 3" step="3">
                Pick a subcategory
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-card class="mt-1" flat>
                  <v-row no-gutters>
                    <v-col class="col-sm-12 col-md-4 col-lg-4">
                      <v-autocomplete label="Subcategories" :items="subcategories"
                        :item-text="(item) => `${item.subcategory}`" item-value="_id" v-model="dataToSend.subcategoryId"
                        v-validate="'required'" required chips deletable-chips small-chips dense outlined
                        data-vv-name="subcategories" :error-messages="errors.collect('subcategories')">
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>

                <v-row no-gutters>
                  <v-btn class="ma-2" color="primary" fab small @click="e1 = 2">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  <v-btn class="ma-2" color="success" fab small @click="loadBrands()"
                    :disabled="dataToSend.subcategoryId == undefined">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-row>
              </v-stepper-content>

              <v-stepper-step color="#30D5C8" :complete="e1 > 4" step="4">
                Pick a brand
              </v-stepper-step>
              <v-stepper-content step="4">
                <v-card class="mt-1" flat>
                  <v-row no-gutters>
                    <v-col class="col-sm-12 col-md-4 col-lg-4">
                      <v-autocomplete label="Brands" :items="brands" :item-text="(item) => `${item.brand}`"
                        item-value="_id" v-model="dataToSend.brandId" v-validate="'required'" required chips
                        deletable-chips small-chips dense outlined data-vv-name="brands"
                        :error-messages="errors.collect('brands')">
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>

                <v-row no-gutters>
                  <v-btn class="ma-2" fab small color="primary" @click="e1 = 3">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  <v-btn class="ma-2" color="success" fab small @click="redirectPage()"
                    :disabled="dataToSend.brandId == undefined">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-row>
              </v-stepper-content>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="showMessage">
      <p>The feature is not supported in your country</p>
    </v-container>
  </div>
</template>


<script>
import { ReviewDataService } from "../../services/DataServiceiReview";

const reviewDataService = new ReviewDataService();

export default {
  data() {
    return {
      segments: [],
      categories: [],
      subcategories: [],
      brands: [],
      dataToSend: [],
      e1: 1,
      isValid: false,
      showMessage: false,
      redirectTo: "",
      // country: ''
    };
  },
  mounted() {
    this.redirectTo = "ireviewInfo";
    this.loadSegments()
    this.country = this.$commonProcessor.userCountry(this.$store.state.userProfile)
  },

  methods: {
    async loadSegments() {
      this.$setLoader()
      let dataToSend = { country: this.$commonProcessor.userCountry(this.$store.state.userProfile) };
      // let dataToSend = { country: 'Malaysia' };
      reviewDataService.getSegments(dataToSend).then((res) => {
        this.segments = res.segments;
        if (this.segments.length > 0) {
          this.isValid = true;
        } else {
          this.showMessage = true;
        }
        this.$clearLoader();
      });
    },

    async loadCategories() {
      this.$setLoader();
      await reviewDataService.getCategories({ segmentId: this.dataToSend.segmentId }).then((res) => {
        this.categories = res.categories;
        this.$clearLoader();
        this.e1 = 2;
      });
    },

    async loadSubcategories() {
      this.$setLoader();
      await reviewDataService.getSubcategories({ categoryId: this.dataToSend.categoryId }).then((res) => {
        this.subcategories = res.subcategories;
        this.$clearLoader();
        this.e1 = 3;
      });
    },

    async loadBrands() {
      this.$setLoader();
      await reviewDataService.getBrands({ subcategoryId: this.dataToSend.subcategoryId }).then((res) => {
        this.brands = res.brands;
        this.$clearLoader();
        this.e1 = 4;
      });
    },


    redirectPage() {
      let data = {
        segmentId: this.dataToSend.segmentId,
        categoryId: this.dataToSend.categoryId,
        subCategoryId: this.dataToSend.subcategoryId,
        brandId: this.dataToSend.brandId,
        userId: this.$store.state.userProfile._id,
        country: this.country
      };

      this.$router.push({ name: this.redirectTo, params: { data } });
    },
  },
};
</script>
<style scoped>
#bt1 {
  float: right;
}
</style>